import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Tooltip, Upload, message } from 'antd';
import React, { useState } from 'react';
import { FormItemList, TagsSelector } from '../../../components';

const SitesInput = ({ value: sites = '', onChange: setSites }) => {
  const [sitesValue, setSitesValue] = useState(sites);

  const onSitesChange = (updatedSites) => {
    setSitesValue(updatedSites);
    setSites(updatedSites);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (file.name.includes('.txt')) {
        onSitesChange(event.target.result);
      } else {
        message.warn('Wrong file format. Please use .TXT file type only.');
      }
    };

    reader.onerror = () => {
      message.error('Error reading file.');
    };

    reader.readAsText(file);
    return false;
  };

  return (
    <div>
      <Input.TextArea
        autoSize={{ minRows: 8, maxRows: 8 }}
        placeholder="Enter each site on a new line - e.g. &#13;domain1.com &#13;domain2.com"
        value={sitesValue}
        onChange={(event) => onSitesChange(event.target.value)}
      />
      <Upload beforeUpload={handleFileUpload} showUploadList={false}>
        <Button icon={<UploadOutlined />} style={{ margin: '8px 6px 0px 0px' }}>
          Upload sites
        </Button>
        <Tooltip title="Upload a .txt file with each value on a new line.">
          <ExclamationCircleOutlined />
        </Tooltip>
      </Upload>
    </div>
  );
};

const DisableBTXBiddersForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="disable-btx-bidders"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <FormItemList
      name="value"
      disabled={isViewOnly}
      addPrompt="Add bidders to remove"
      renderFormItem={({ field, disabled }) => (
        <Form.Item>
          <Form.Item
            {...field}
            key={`${field.key}-when-site`}
            name={[field.name, 'whenSite']}
            label="If site one of"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: 'Please input at least 1 site!',
              },
            ]}
          >
            <SitesInput />
          </Form.Item>
          <Form.Item
            {...field}
            key={`${field.key}-bidders`}
            name={[field.name, 'bidders']}
            label="Remove"
            colon={false}
            rules={[
              {
                required: true,
                message: 'Please input at least 1 bidder!',
              },
            ]}
          >
            <TagsSelector addPrompt="Bidder" disabled={disabled} />
          </Form.Item>
        </Form.Item>
      )}
    />
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Disable BTX Bidders',
  description: 'Disable BTX Bidders for specified websites.',
  Form: DisableBTXBiddersForm,
  transformBeforeInit: (disableBTXBiddersConfig) =>
    disableBTXBiddersConfig.map(({ whenSite, ...rest }) => ({
      whenSite: whenSite.join('\n'),
      ...rest,
    })),
  transformBeforeSave: (disableBTXBiddersConfig) =>
    disableBTXBiddersConfig.map(({ whenSite, ...rest }) => ({
      whenSite: Array.from(new Set(whenSite.split('\n').filter(Boolean))),
      ...rest,
    })),
  initialSettingValue: [],
};

export default settingConfig;
