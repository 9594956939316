import { Form, Select } from 'antd';
import CustomCTAOptions from './CustomCTAOptions';
import {useEffect} from "react";

export const MODE_FOOTER = "footer";
export const MODE_MODAL = "modal";
export const MODE_CUSTOM_CTA = "custom-cta-modal";

const defaultView = MODE_MODAL;

const ModeFormItem = ({ env, form, disabled }) => {
  const hardWallModeFieldName = [env, 'am_settings', 'hard_message_wall_mode_enabled'];
  const viewModeFieldName = [env, 'am_settings', 'view'];
  const adaptiveHardWallModeFieldName = [env, 'am_settings', 'adaptive_hard_message_wall_mode_enabled'];
  const isHardWallEnabled = Form.useWatch(hardWallModeFieldName, form);
  const viewMode = Form.useWatch(viewModeFieldName, form);
  const shouldDisableViewSelector = isHardWallEnabled && viewMode === MODE_MODAL;

  useEffect(() => {
    // set default if value is empty
    if (viewMode === "") {
      form.setFieldValue(viewModeFieldName, defaultView);
    }
  }, [form, env, viewMode]);

  const onViewModeChanged = (mode) => {
    if (mode === MODE_FOOTER) {
      // Ensure Hard Wall Mode is disabled if user sets the Style (View Mode) to 'Footer'
      form.setFieldValue(hardWallModeFieldName, false);
      form.setFieldValue(adaptiveHardWallModeFieldName, false);
    }
  };

  return (
    <>
      <Form.Item
        label="Style"
        name={viewModeFieldName}
        tooltip="When Hard Mode is enabled, view mode can only be set to Modal."
      >
        <Select
          defaultValue={defaultView}
          disabled={disabled || shouldDisableViewSelector}
          onChange={onViewModeChanged}
          options={[
            {
              label: 'Modal',
              value: MODE_MODAL,
            },
            {
              label: 'Footer',
              value: MODE_FOOTER,
            },
            {
              label: 'Custom CTA Modal',
              value: MODE_CUSTOM_CTA,
            },
          ]}
        />
      </Form.Item>
      {viewMode === MODE_CUSTOM_CTA ? <CustomCTAOptions env={env} form={form} /> : null}
    </>
  );
};

export default ModeFormItem;
