import { Form, Input } from 'antd';

const TitleFormItem = ({ env, language, placeholder }) => (
  <Form.Item
    label="Title"
    name={[env, 'am_settings', 'i18n', language, 'title']}
    tooltip="Use the {{name}} macro to insert the Org or Website name, depending on where the RL feature is enabled."
  >
    <Input placeholder={placeholder} />
  </Form.Item>
);

export default TitleFormItem;
