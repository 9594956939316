import { useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import './CustomTooltip.less';
import { compatNumberFormatter as formatter } from '../../services/utils';
import { Form, Select } from 'antd';

const heatMapOptions = [
    { value: 'renders', label: 'Renders' },
    { value: 'non_ab_page_views', label: 'Non AB PVs' },
    { value: 'ab_page_views', label: 'AB PVs' },
    { value: 'aa_page_views', label: 'AA PVs' },
    { value: 'ab_recoveries', label: 'AB Recoveries' },
    { value: 'aa_recoveries', label: 'AA Recoveries' },
]
const defaultHeatOption = heatMapOptions[0]

const tooltipHTML = `
    <div class="custom-tooltip">
        <div class="name">{name}</div>
        <div class="stats">
                <div class="stats-item">
                    <span>Non AB page views:</span>
                    <span>{data.non_ab_page_views}</span>
                </div>
                <div class="stats-item">
                    <span>AB page views:</span>
                    <span>{data.ab_page_views}</span></div>
                <div class="stats-item">
                    <span>AA page views:</span>
                    <span>{data.aa_page_views}</span>
                </div>
                <div class="stats-item">
                    <span>Renders:</span>
                    <span>{data.renders}</span>
                </div>
                <div class="stats-item">
                    <span>AB recoveries:</span>
                    <span>{data.ab_recoveries}</span>
                </div>
                <div class="stats-item">
                    <span>AA recoveries:</span>
                    <span>{data.aa_recoveries}</span>
                </div>
        </div>
    </div>
`

const MapChart = ({ data }) => {
    const [dataJSON, setDataJSON] = useState([])
    const [heatOption, setHeatOption] = useState(defaultHeatOption['value'])

    useEffect(() => {
        if (!data) {
            return;
        }
        let newJSON = data.map(j => ({
            id: j.id,
            value: Number(j[heatOption]),
            data: {
                non_ab_page_views: formatter.format(Number(j.non_ab_page_views)),
                ab_page_views: formatter.format(Number(j.ab_page_views)),
                aa_page_views: formatter.format(Number(j.aa_page_views)),
                renders: formatter.format(Number(j.renders)),
                ab_recoveries: formatter.format(Number(j.ab_recoveries)),
                aa_recoveries: formatter.format(Number(j.aa_recoveries)),
            }
        }));

        setDataJSON(newJSON)
    }, [heatOption, data])

    useEffect(() => {
        let root = am5.Root.new("chartdiv");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5map.MapChart.new(root, {
                panX: "rotateX",
                panY: "none",
                projection: am5map.geoNaturalEarth1(),
                layout: root.horizontalLayout
            })
        );

        // Create polygon series
        const polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                valueField: "value",
                calculateAggregates: true
            })
        );

        polygonSeries.mapPolygons.template.setAll({
            tooltipHTML: tooltipHTML,
        });
        polygonSeries.set("heatRules", [{
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0x90d5ff),
            max: am5.color(0x111184),
            key: "fill"
        }]);

        let heatLegend = chart.children.push(am5.HeatLegend.new(root, {
            orientation: "vertical",
            startColor: am5.color(0x90d5ff),
            endColor: am5.color(0x111184),
            startText: "Lowest",
            endText: "Highest",
            stepCount: 5
        }));

        heatLegend.startLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("startColor")
        });

        heatLegend.endLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("endColor")
        });

        polygonSeries.mapPolygons.template.events.on("pointerover", (ev) => {
            heatLegend.showValue(ev.target.dataItem.get("value"));
        });

        polygonSeries.data.setAll(dataJSON)

        // change this to template when possible
        polygonSeries.events.on("datavalidated", () => {
            heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
            heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
        });

        return () => {
            root.dispose();
        };
    }, [dataJSON]);

    const [formInstance] = Form.useForm();

    const onHeatChange = (value) => {
        setHeatOption(value)
    }

    return (
        <div>
            <div>
                <Form
                    name="settings-add-bt-demand"
                    form={formInstance}
                >
                    <Form.Item name={['value']} label="Heat map by:">
                        <Select defaultValue={defaultHeatOption} options={heatMapOptions} size="small" style={{ width: 150 }} onChange={onHeatChange} />
                    </Form.Item>
                </Form>
            </div>
            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
        </div>
    );
}

export default MapChart;
