export const determineWallMode = (settings) => {
  const { hard_message_wall_mode_enabled, adaptive_hard_message_wall_mode_enabled } = settings;

  if (adaptive_hard_message_wall_mode_enabled) return 'ADAPTIVE_WALL';
  if (hard_message_wall_mode_enabled) return 'HARD_WALL_ALL';
  return 'SOFT_WALL_ALL';
};

export const getSettingsForMode = (mode) => {
  const baseSettings = {
    single_click_enabled: true,
    allow_render_to_aa_users: true,
  };

  switch (mode) {
    case 'SOFT_WALL_ALL':
      return {
        ...baseSettings,
        hard_message_wall_mode_enabled: false,
        adaptive_hard_message_wall_mode_enabled: false,
      };
    case 'HARD_WALL_ALL':
      return {
        ...baseSettings,
        hard_message_wall_mode_enabled: true,
        adaptive_hard_message_wall_mode_enabled: false,
      };
    case 'ADAPTIVE_WALL':
      return {
        ...baseSettings,
        hard_message_wall_mode_enabled: false,
        adaptive_hard_message_wall_mode_enabled: true,
      };
    default:
      return baseSettings;
  }
};
