import { Form, Select, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { getSettingsForMode } from '../../../utils/wallModeUtils';
import { useEffect } from 'react';

const WALL_MODE_OPTIONS = [
  {
    value: 'SOFT_WALL_ALL',
    label: 'Soft Wall - All users can dismiss the wall (Recommended)',
  },
  {
    value: 'HARD_WALL_ALL',
    label: 'Hard Wall - All users must allow ads to continue',
  },
  {
    value: 'ADAPTIVE_WALL',
    label:
      'Adaptive Wall - Renders hard wall to AA opted out users only, and soft wall for AA opted in users',
  },
];

const WallModeSelector = ({ env, form, disabled }) => {
  const viewMode = Form.useWatch([env, 'am_settings', 'view'], form);
  const currentWallMode = Form.useWatch([env, 'am_settings', 'wall_mode'], form);
  const isModalView = viewMode === 'modal';

  // Filter options based on view mode
  const availableOptions = WALL_MODE_OPTIONS.filter((option) => {
    if (!isModalView) {
      return option.value === 'SOFT_WALL_ALL';
    }
    return true;
  });

  const handleModeChange = (mode) => {
    const settings = getSettingsForMode(mode);
    form.setFieldsValue({
      [env]: {
        am_settings: {
          ...form.getFieldValue([env, 'am_settings']),
          ...settings,
          wall_mode: mode,
        },
      },
    });
  };

  // Reset to soft wall if view mode changes from modal to something else
  useEffect(() => {
    if (!isModalView && currentWallMode !== 'SOFT_WALL_ALL') {
      handleModeChange('SOFT_WALL_ALL');
    }
  }, [viewMode, currentWallMode, isModalView]);

  return (
    <Form.Item
      label={
        <Space>
          Wall Mode
          <Tooltip
            title={
              <>
                Choose how your message wall interacts with different user groups
                <br />
                <small>AA = Acceptable Ads</small>
              </>
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
      name={[env, 'am_settings', 'wall_mode']}
    >
      <Select
        disabled={disabled}
        onChange={handleModeChange}
        options={availableOptions}
        value={currentWallMode}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
};

export default WallModeSelector;
