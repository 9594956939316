import { determineWallMode } from '../../utils/wallModeUtils';
import {
  MessageWallPreview,
  MultiLanguageInput,
  StyleInput,
  ModeFormItem,
  UploadLogoFormItem,
  RenderMode,
  SelectCountry,
  ScriptLoadingMode,
  APIMode,
  AllowListURL,
  FormGroupItem,
  WallModeSelector,
} from './FormComponents';
import { Button, Form, Input, Switch, Space } from 'antd';
import { useEffect } from 'react';

const CommonForm = ({
  isWebsite,
  formInstance,
  env,
  isViewOnly,
  initialValue,
  update,
  hideOverride,
  shouldDisableScriptLoadingMode,
  org,
  onPromoteToProd,
}) => {
  useEffect(() => {
    if (initialValue) {
      const wallMode = determineWallMode(initialValue[env].am_settings);
      const formData = {
        ...initialValue,
        [env]: {
          ...initialValue[env],
          am_settings: {
            ...initialValue[env].am_settings,
            wall_mode: wallMode,
          },
        },
      };
      formInstance.setFieldsValue(formData);
    }
  }, [initialValue, env, formInstance]);

  return (
    <>
      <Form
        form={formInstance}
        disabled={isViewOnly}
        initialValues={initialValue}
        onFinish={update}
        autoComplete="off"
      >
        <FormGroupItem label="General">
          <Form.Item
            label="RL Feature"
            name={[env, 'am_settings', 'enabled']}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          {isWebsite && (
            <Form.Item
              label="Override Organization Settings"
              name={[env, 'am_settings', 'override_org']}
              valuePropName="checked"
              hidden={hideOverride}
            >
              <Switch />
            </Form.Item>
          )}
          <ScriptLoadingMode env={env} disabled={shouldDisableScriptLoadingMode} orgId={org.id} />
          <RenderMode env={env} orgId={org.id} />
          <APIMode env={env} orgId={org.id} />
          <Form.Item
            label="Attribution Window In Days"
            name={[env, 'am_settings', 'attribution_window_days']}
            tooltip="The Attribution Window determines the period during which conversions are credited. The value should fall within the 396 days range. If the value is not set the Attribution will expire in 365 days by default."
          >
            <Input type="number" min={0} max={396} />
          </Form.Item>
        </FormGroupItem>

        <FormGroupItem label="Branding and Style">
          <UploadLogoFormItem name={[env, 'am_settings', 'logo']} />
          <Form.Item
            label="Disable automatic detection"
            name={[env, 'am_settings', 'disable_automatic_detection']}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <ModeFormItem env={env} form={formInstance} disabled={isViewOnly} />
          <StyleInput env={env} formInstance={formInstance} />
        </FormGroupItem>

        <FormGroupItem label="Mode Control">
          <WallModeSelector env={env} form={formInstance} disabled={isViewOnly} />
          <Form.Item
            label="Premium Bypass"
            name={[env, 'am_settings', 'premium_bypass_mode_enabled']}
            valuePropName="checked"
            tooltip="RL does not render for users with premium subscription"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Page Views To Render"
            name={[env, 'am_settings', 'page_views_to_render']}
          >
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item
            label="Render Interval In Days"
            name={[env, 'am_settings', 'render_interval_days']}
          >
            <Input type="number" min={0} />
          </Form.Item>
        </FormGroupItem>

        <FormGroupItem label="Localization">
          <MultiLanguageInput env={env} formInstance={formInstance} />
        </FormGroupItem>

        <FormGroupItem label="Geographical Control">
          <SelectCountry env={env} form={formInstance} />
        </FormGroupItem>

        <FormGroupItem label="Allowlist Management">
          <AllowListURL env={env} form={formInstance} />
        </FormGroupItem>

        <Space direction="horizontal">
          <MessageWallPreview formInstance={formInstance} env={env} orgId={org.id} />

          {isViewOnly ? null : (
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          )}

          {env === 'stg_params' && (
            <Button type="default" onClick={onPromoteToProd}>
              Promote to prod
            </Button>
          )}
        </Space>
      </Form>
    </>
  );
};

export default CommonForm;
