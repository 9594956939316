import React from 'react';
import useSWR from 'swr';
import { isNaN, isNil } from 'lodash';
import { Col, Row } from 'antd';
import api from '../../services/api';
import { MetricsCard } from '../../components';

const LostAdRevenueMetrics = ({ org, dateRange }) => {
  const { id: orgId } = org;
  const [dateStartString, dateEndString] = dateRange;

  const swrKeyProps = [orgId, dateStartString, dateEndString];

  const resourceScopedParams = {
    resource_type: 'org',
    id: orgId,
    start_date: dateStartString,
    end_date: dateEndString,
  };

  const { data: pageviews, error: pageviewsError, isLoading: pageviewsLoading } = useSWR(
    ['/PageViewMetrics', ...swrKeyProps],
    () => api.getPageviewMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );

  const { data: rpm, error: rpmError, isLoading: rpmLoading } = useSWR(
    ['/RPMMetrics', ...swrKeyProps],
    () => api.getRPMMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );

  const { data: abViews, error: abError, isLoading: abViewsLoading } = useSWR(
    ['/ABPageViewMetrics', ...swrKeyProps],
    () => api.getABPageviewMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );

  const avgRpm = !isNil(rpm?.total) ? (rpm.total / rpm.data.length).toFixed(2) : 0;
  const lostAdRevenueValue = (abViews?.total * avgRpm) / 1000;
  const adBlockRateValue = (abViews?.total / pageviews?.total) * 100;

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} sm={8}>
          <MetricsCard
            title="Pageviews"
            value={pageviews?.total}
            error={pageviewsError}
            loading={pageviewsLoading}
          />
        </Col>
        <Col xs={24} sm={8}>
          <MetricsCard
            title="Lost Ad Revenue"
            value={!isNaN(lostAdRevenueValue) ? lostAdRevenueValue : 0}
            valueProps={{ prefix: '$', precision: 2 }}
            loading={abViewsLoading || rpmLoading}
            error={abError || rpmError}
            statisticStyle={{ color: 'red' }}
            help="The calculated amount of potential revenue that Readership Link could recover from adblocking visitors. This utilizes blocked pageviews and RPM."
          />
        </Col>
        <Col xs={24} sm={8}>
          <MetricsCard
            title="Adblocking Rate"
            value={!isNaN(adBlockRateValue) ? adBlockRateValue : 0}
            valueProps={{ suffix: '%', precision: 1 }}
            loading={abViewsLoading || pageviewsLoading}
            error={abError || pageviewsError}
            statisticStyle={{ color: 'red' }}
            help="% of pageviews blocking ads"
          />
        </Col>
      </Row>
    </>
  );
};

export default LostAdRevenueMetrics;
