import { Form, Modal, Select, Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import { sortAlphabetically } from '../../../services/utils';
import { isDimensionSelectable } from './predicates';

export const getFiltersSelectValue = ({ value = {} }) => {
  // Omit filters with no filter options selected (e.g. `country: []`)
  const selectedFilterKeys = Object.entries(value).reduce(
    (keys, [filterKey, filterValue]) => (filterValue?.length > 0 ? [...keys, filterKey] : keys),
    []
  );

  return selectedFilterKeys;
};

const getFiltersSelectOptions = ({ filters, value = {}, dimensionsByValue, selectedMetrics }) => {
  const selectedFilterKeys = getFiltersSelectValue({ value });

  // Omit filters which have already been selected or conflict with selected metrics from Select options
  return filters
    .filter(
      (filter) =>
        !selectedFilterKeys.includes(filter.value) &&
        isDimensionSelectable({
          dimension: dimensionsByValue[filter.value],
          selectedMetrics,
        })
    )
    .map(({ label, value }) => ({
      label,
      value,
    }));
};

const FiltersSelect = ({ value, onChange, filters, dimensions, selectedMetrics }) => {
  const [isFilterModalVisible, setIsFilterModalOpen] = useState(false);

  const openModal = () => setIsFilterModalOpen(true);
  const closeModal = () => setIsFilterModalOpen(false);

  const [filterModalProps, setFilterModalProps] = useState({
    onCancel: closeModal,
  });
  const [filterForm] = Form.useForm();
  const [filterModalContents, setFilterModalContents] = useState({});

  const filtersByKey = useMemo(
    () => filters.reduce((obj, filter) => ({ ...obj, [filter.value]: filter }), {}),
    [filters]
  );

  const dimensionsByValue = useMemo(
    () => dimensions.reduce((acc, dimension) => ({ ...acc, [dimension.value]: dimension }), {}),
    [dimensions]
  );

  const formatFilterModalTitle = (filterKey) =>
    `${filtersByKey[filterKey]?.label} - Report Dimensions Filter`;

  const onFilterSelect = (filterKey) => {
    const filterOptions = filtersByKey[filterKey]?.options;
    const options =
      filterKey === 'website_id' ? sortAlphabetically(filterOptions, 'label') : filterOptions;
    setFilterModalProps((prevFilterModalProps) => ({
      ...prevFilterModalProps,
      title: formatFilterModalTitle(filterKey),
      onOk: () => {
        const filterValue = filterForm.getFieldValue(filterKey);
        onChange({ ...value, [filterKey]: filterValue });

        closeModal();
      },
    }));
    setFilterModalContents(() => ({
      filterKey,
      filterLabel: filtersByKey[filterKey]?.label,
      options,
    }));

    // Reset form fields & set the initial value of the filter's selected options (if applicable)
    filterForm.resetFields();
    if (value?.[filterKey]) {
      filterForm.setFieldsValue({ [filterKey]: value[filterKey] });
    }

    openModal();
  };

  const tagRender = (props) => {
    const { value: filterKey, closable, onClose } = props;
    const numFilterOptions = value?.[filterKey]?.length;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        key={filterKey}
        onMouseDown={onPreventMouseDown}
        onClick={() => {
          onFilterSelect(filterKey);
        }}
        closable={closable} // to support 'disabled' (and thus unclosable options)
        onClose={() => {
          // Clear filter in filters value
          onChange({ ...value, [filterKey]: [] });

          onClose(); // not really necessary, but following antd convention
        }}
        style={{
          marginRight: 3,
        }}
      >
        {filtersByKey[filterKey].label}
        {numFilterOptions ? ` (${numFilterOptions})` : null}
      </Tag>
    );
  };

  return (
    <>
      <Select
        mode="multiple"
        showSearch={false}
        notFoundContent="No more filters can be added"
        value={getFiltersSelectValue({ value })}
        options={getFiltersSelectOptions({ filters, value, dimensionsByValue, selectedMetrics })}
        onSelect={onFilterSelect}
        tagRender={tagRender}
      />
      <Modal open={isFilterModalVisible} {...filterModalProps} bodyStyle={{ marginBottom: 100 }}>
        <Form form={filterForm}>
          <Form.Item name={filterModalContents.filterKey} label={filterModalContents.filterLabel}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Select..."
              options={filterModalContents.options}
              optionFilterProp="label"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FiltersSelect;
