import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import useSWR from 'swr';
import './App.less'; // Ensure this is imported before our pages/components to give our page/component CSS styles higher priority!
import { OnceLoaded } from './components';
import { Container, LoggedOutContainer } from './layout';
import {
  Error,
  ErrorRedirect,
  ForgotPassword,
  InviteAccepted,
  Login,
  Metrics,
  RLMetrics,
  Organization,
  Organizations,
  Reports,
  ResetPassword,
  Users,
  Website,
} from './pages';
import api, { setupAuthInterceptor } from './services/api';
import { useSessionUser } from './services/hooks';
import { useStore } from './services/store';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { state } = useStore();

  const isLoggedIn = state.isAuthenticated();

  // Redirect user to the /login page if not authenticated, but save the location they were trying to access for later redirect once user logs in
  // Otherwise, render the children elements which required the user be authenticated
  return isLoggedIn ? children : <Navigate to="/login" state={{ from: location }} replace />;
};

/**
 * Waits for the session user data to be loaded, and once obtained, will render the contents
 * in `children` if the logged-in user has one of the `permittedRoles` assigned to them.
 * Otherwise, redirects the logged-in user to a 404 'Not Found' page.
 * (NOTE: Internal users will always be given access, regardless of the `permittedRoles` passed in.)
 */
const RequirePermissions = ({ permittedRoles = [], children }) => {
  const { isLoaded: userIsLoaded, hasAccessForAnyOrg: userHasAccess } = useSessionUser();

  return (
    <OnceLoaded isLoading={!userIsLoaded}>
      {userHasAccess({ permittedRoles }) ? children : <Error code="404" />}
    </OnceLoaded>
  );
};

const LoadUserSession = () => {
  const { dispatch } = useStore();

  // Fetch logged-in user's data & once fetched, load into store
  const { data, error } = useSWR('/Session', api.getSession, { revalidateOnFocus: false });
  useEffect(() => {
    if (error?.response?.status === 401) {
      // If the user is not logged in, clear the user data from the store
      dispatch({ type: 'UNAUTHENTICATE' });
    }
  }, [error, dispatch]);
  const user = data?.user;
  useEffect(() => {
    if (user) {
      dispatch({ type: 'LOAD_USER', user });
    }
  }, [user, dispatch]);

  return null;
};

const App = () => {
  const { state, dispatch } = useStore();
  useEffect(() => {
    setupAuthInterceptor(dispatch);
  }, [dispatch]);

  // Modify the <title> for local/dev builds
  useEffect(() => {
    if (state.isDev && !document.title.startsWith('[DEV] ')) {
      document.title = `[DEV] ${document.title}`;
    }
  }, [state.isDev]);

  return (
    <Router>
      <Routes>
        <Route
          path="login"
          element={
            <LoggedOutContainer>
              <Login />
            </LoggedOutContainer>
          }
        />
        <Route
          path="forgot_password"
          element={
            <LoggedOutContainer>
              <ForgotPassword />
            </LoggedOutContainer>
          }
        />
        <Route
          path="set_password"
          element={
            <LoggedOutContainer>
              <ResetPassword />
            </LoggedOutContainer>
          }
        />
        <Route
          path="invite_accepted"
          element={
            <LoggedOutContainer>
              <InviteAccepted />
            </LoggedOutContainer>
          }
        />
        <Route path="error" element={<ErrorRedirect />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <LoadUserSession />
              <Container>
                <Outlet />
              </Container>
            </RequireAuth>
          }
        >
          <Route index element={<Metrics />} />
          <Route path="readership-link" element={
            <RequirePermissions permittedRoles={["orgadmin", "orguser"]}>
              <RLMetrics />
            </RequirePermissions>} >
          </Route>
          <Route
            path="orgs"
            element={
              <RequirePermissions>
                <Organizations />
              </RequirePermissions>
            }
          />
          <Route
            path="orgs/:orgId"
            element={
              <RequirePermissions>
                <Organization />
              </RequirePermissions>
            }
          />
          <Route
            path="websites/:websiteId"
            element={
              <RequirePermissions>
                <Website />
              </RequirePermissions>
            }
          />
          <Route
            path="users"
            element={
              <RequirePermissions permittedRoles={['orgadmin']}>
                <Users />
              </RequirePermissions>
            }
          />
          <Route path="reports/*" element={<Reports />} />
          <Route path="*" element={<Error code="404" />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
