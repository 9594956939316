import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment/moment";
import { SEARCH_PARAM_KEYS } from "../services/constants";

const YESTERDAY = moment().subtract(1, 'day');

export const useScopePageByDateRange = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateStartParam = searchParams.get(SEARCH_PARAM_KEYS.DATE_START);
  const dateEndParam = searchParams.get(SEARCH_PARAM_KEYS.DATE_END);

  // Ensure start & end dates from params are valid and are not set for dates any later than "Yesterday"
  // (The default/fallback date range is 'Last 30 days' (excluding "Today"))
  let dateStart = moment(dateStartParam, 'YYYY-MM-DD');
  if (!dateStart.isValid() || dateStart.isAfter(YESTERDAY, 'day')) {
    dateStart = moment().subtract(30, 'days');
  }
  let dateEnd = moment(dateEndParam, 'YYYY-MM-DD');
  if (!dateEnd.isValid() || dateEnd.isAfter(YESTERDAY, 'day') || dateEnd.isBefore(dateStart)) {
    // (An end date set before the start date is invalid)
    dateEnd = YESTERDAY;
  }
  // Ensure date range doesn't span more than 1 year
  if (dateEnd.diff(dateStart, 'years', true) > 1) {
    // Set start date to 1 year before the end date
    dateStart = moment(dateEnd).subtract(1, 'year');
  }

  const dateStartString = moment.utc(dateStart.format('YYYY-MM-DD')).format();
  const dateEndString = moment.utc(dateEnd.format('YYYY-MM-DD')).endOf('day').format();

  const scopedDateRange = [dateStartString, dateEndString];

  const dateRangePickerProps = {
    value: [dateStart, dateEnd],
    onChange: useCallback(
      (selectedDateRange) => {
        const [dateStart, dateEnd] = selectedDateRange || [];

        const newSearchParams = new URLSearchParams(searchParams);
        if (dateStart && dateEnd) {
          newSearchParams.set(SEARCH_PARAM_KEYS.DATE_START, dateStart.format('YYYY-MM-DD'));
          newSearchParams.set(SEARCH_PARAM_KEYS.DATE_END, dateEnd.format('YYYY-MM-DD'));
        }
        setSearchParams(newSearchParams);
      },
      [searchParams, setSearchParams]
    ),
  };

  return { scopedDateRange, dateRangePickerProps };
}
