import React, { useMemo } from 'react';
import { Alert, PageHeader } from 'antd';
import moment from 'moment';
import useSWR from 'swr';
import {
  OnceLoaded,
  ScopePageByOrg,
  ScopePageForm,
  useScopePageByOrg,
  ScopePageByDateRange,
} from '../../components';
import { PageContents } from '../../layout';
import api from '../../services/api';

import styles from '../../components/Metrics/Metrics.module.less';
import { useSessionUser } from '../../services/hooks';
import RLMetricsView from './RLMetricsView';
import LostAdRevenueMetrics from './LostAdRevenueMetrics';
import { useScopePageByDateRange } from '../../hooks';

const LearnMorePageContents = () => (
  <Alert
    message={
      <span>
        {'Connect with your account manager or reach out to us via email at\u00A0'}
        <a
          href="mailto:pubsupport@blockthrough.com"
          style={{ color: 'inherit', textDecoration: 'underline' }}
        >
          pubsupport@blockthrough.com
        </a>
        .<br />
        <a
          href="https://youtu.be/GkV7tQNoEHs?feature=shared"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about Readership Link
        </a>
      </span>
    }
    style={{ marginBottom: 16 }}
  />
);

const MetricsPageContents = ({ org, dateRange, isAdmin }) => {
  const { id: orgId, name: orgName } = org;
  const [dateStartString, dateEndString] = dateRange;

  const swrKeyProps = [orgId, dateStartString, dateEndString];

  const orgScopedParams = {
    org_id: orgId,
    start_date: dateStartString,
    end_date: dateEndString,
  };

  const { data: mwrMetrics, error: mwrMetricsError, isLoading: mwrMetricsLoading } = useSWR(
    ['/MWRMetrics', ...swrKeyProps],
    () => api.getMessageWallRecoveryMetrics(orgScopedParams),
    { revalidateOnFocus: false }
  );
  const {
    data: adblockerMetrics,
    error: adBlockUsersError,
    isLoading: adblockerMetricsLoading,
  } = useSWR(
    ['/AdblockerBreakdown', ...swrKeyProps],
    () => api.getAdblockerBreakdownMetrics(orgScopedParams),
    { revalidateOnFocus: false }
  );
  const {
    data: performanceMetrics,
    error: performanceError,
    isLoading: performanceMetricsLoading,
  } = useSWR(
    ['/RLPerformance', ...swrKeyProps],
    () => api.getRLPerformanceMetrics(orgScopedParams),
    { revalidateOnFocus: false }
  );
  const {
    data: geoPerformanceMetrics,
    error: geoPerformanceError,
    isLoading: geoPerformanceMetricsLoading,
  } = useSWR(
    ['/RLGeoPerformance', ...swrKeyProps],
    () => api.getRLGeoPerformanceMetrics(orgScopedParams),
    { revalidateOnFocus: false }
  );

  const performanceData = useMemo(() => {
    if (performanceMetrics && performanceMetrics.metrics) {
      return performanceMetrics.metrics.map((v) => ({
        ...v,
        date: moment.utc(v.date).format('YYYY-MM-DD'),
      }));
    }
    return [];
  }, [performanceMetrics]);

  return (
    <>
      {!mwrMetrics && !performanceMetrics ? <LearnMorePageContents /> : null}
      {isAdmin && <LostAdRevenueMetrics org={org} dateRange={dateRange} />}
      <RLMetricsView
        dateRange={dateRange}
        org={{ name: orgName, id: orgId }}
        mwMetrics={{ data: mwrMetrics, error: mwrMetricsError, loading: mwrMetricsLoading }}
        abMetrics={{
          data: adblockerMetrics,
          error: adBlockUsersError,
          loading: adblockerMetricsLoading,
        }}
        performanceMetrics={{
          data: performanceData,
          error: performanceError,
          loading: performanceMetricsLoading,
        }}
        geoPerformanceMetrics={{
          data: geoPerformanceMetrics,
          error: geoPerformanceError,
          loading: geoPerformanceMetricsLoading,
        }}
      />
    </>
  );
};

const RLMetrics = () => {
  // Metrics displayed are scoped to a selected org & date range which are stored in their corresponding URL search params
  // (Updates to the selected org or date range will trigger new API calls to fetch updated metrics)
  const { scopedOrg, orgSelectProps, orgsError } = useScopePageByOrg();
  const { scopedDateRange, dateRangePickerProps } = useScopePageByDateRange();

  const { hasAccessToRLMetrics, isSystemAdmin } = useSessionUser();

  const metricsPageContent =
    scopedOrg?.id > 0 ? (
      <OnceLoaded
        error={orgsError}
        isLoading={!(scopedOrg && scopedDateRange)}
        render={() => <MetricsPageContents org={scopedOrg} dateRange={scopedDateRange} isAdmin={isSystemAdmin} />}
      />
    ) : (
      <Alert message="choose organization" />
    );

  const lostAdRevenuePage = (
    <>
      <LearnMorePageContents />
      <LostAdRevenueMetrics org={scopedOrg} dateRange={scopedDateRange} />
      <RLMetricsView
        dateRange={scopedDateRange}
        org={{ name: scopedOrg?.orgName, id: scopedOrg?.orgId }}
        mwMetrics={{ data: [] }}
        abMetrics={{ data: [] }}
        performanceMetrics={{ data: [] }}
        geoPerformanceMetrics={{ data: [] }}
      />
    </>
  );

  return (
    <>
      <PageHeader
        ghost={false}
        className={styles.pageHeader}
        title="Readership Link"
        extra={
          <ScopePageForm>
            {hasAccessToRLMetrics && (
              <ScopePageByOrg scopedOrg={scopedOrg} orgSelectProps={orgSelectProps} />
            )}
            <ScopePageByDateRange
              disabled={!hasAccessToRLMetrics}
              dateRangePickerProps={dateRangePickerProps}
            />
          </ScopePageForm>
        }
      />
      <PageContents>{hasAccessToRLMetrics ? metricsPageContent : lostAdRevenuePage}</PageContents>
    </>
  );
};
export default RLMetrics;
