import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import React, { useState, useCallback } from 'react';
import { useAsync } from '../services/hooks';

export function confirmAction({
  action,
  onSuccess,
  prompt = 'Are you sure you want to do this?',
  errorMsgPrefix = 'Action failed',
}) {
  const confirmationModal = Modal.confirm();
  confirmationModal.update({
    icon: <QuestionCircleOutlined />,
    title: prompt,
    okText: 'Confirm',
    onOk: () => {
      confirmationModal.update({ content: null }); // Clear any previous errors we may have displayed

      // Modal closes on Promise resolution
      return action()
        .then(onSuccess)
        .catch((errorMessage) => {
          // Show error message in modal
          confirmationModal.update({
            content: (
              <Alert
                style={{ marginTop: 16 }}
                message={
                  <Space direction="vertical" size="small">
                    <Typography.Text strong>{errorMsgPrefix}</Typography.Text>
                    {errorMessage ? <Typography.Text>{errorMessage}</Typography.Text> : null}
                  </Space>
                }
                type="error"
                showIcon
              />
            ),
          });
          return Promise.reject(errorMessage); // Reject promise so that modal does not close
        });
    },
  });
}

export function confirmDelete({
  deleteEntity,
  onDeleted,
  prompt = 'Are you sure you want to delete this?',
  errorMsgPrefix = 'Deletion failed',
}) {
  const confirmationModal = Modal.confirm();
  confirmationModal.update({
    icon: <QuestionCircleOutlined />,
    title: prompt,
    okText: 'Confirm',
    okType: 'danger',
    onOk: () => {
      confirmationModal.update({ content: null }); // Clear any previous errors we may have displayed

      // Modal closes on Promise resolution
      return deleteEntity()
        .then(() => {
          setTimeout(onDeleted, 10); // try to run `onDeleted` after modal closes on Promise resolution
        })
        .catch((errorMessage) => {
          // Show error message in modal
          confirmationModal.update({
            content: (
              <Alert
                style={{ marginTop: 16 }}
                message={
                  <Space direction="vertical" size="small">
                    <Typography.Text strong>{errorMsgPrefix}</Typography.Text>
                    {errorMessage ? <Typography.Text>{errorMessage}</Typography.Text> : null}
                  </Space>
                }
                type="error"
                showIcon
              />
            ),
          });
          return Promise.reject(errorMessage); // Reject promise so that modal does not close
        });
    },
  });
}

export const DeleteEntityPopconfirm = ({
  isDisabled,
  deleteEntity,
  onDeleted,
  prompt = 'Are you sure you want to delete this?',
  okText = 'Confirm',
  cancelText = 'Cancel',
  triggerRender,
}) => (
  <Popconfirm
    title={prompt}
    okText={okText}
    cancelText={cancelText}
    onConfirm={() => {
      deleteEntity()
        .then(onDeleted)
        .catch((errorMessage) => {
          message.error(`Deletion failed. ${errorMessage}`);
        });
    }}
    placement="topRight"
    disabled={isDisabled}
  >
    {triggerRender ? (
      triggerRender({ isDisabled })
    ) : (
      <Button danger type="link" size="small" disabled={isDisabled}>
        <DeleteOutlined />
      </Button>
    )}
  </Popconfirm>
);

export const ToggleEnablePopconfirm = ({
  isToggled,
  toggleEnable,
  onToggled,
  prompt = 'Are you sure?',
  okText = 'Confirm',
  cancelText = 'Cancel',
  isDisabled,
  useCheckbox,
}) => {
  const { execute: handleToggle, status: toggleStatus } = useAsync(toggleEnable);

  return (
    <Popconfirm
      title={prompt}
      okText={okText}
      cancelText={cancelText}
      onConfirm={() => {
        const newToggleValue = !isToggled;
        handleToggle(newToggleValue)
          .then(onToggled)
          .catch((errorMessage) => {
            message.error(`Update failed. ${errorMessage}`);
          });
      }}
      disabled={isDisabled}
      placement="topRight"
    >
      {useCheckbox ? (
        <Checkbox checked={isToggled} disabled={isDisabled} />
      ) : (
        <Switch checked={isToggled} loading={toggleStatus === 'pending'} disabled={isDisabled} />
      )}
    </Popconfirm>
  );
};

export const SelectPopconfirm = ({
  value,
  options,
  selectValue,
  onSelected,
  prompt = 'Are you sure?',
  okText = 'Confirm',
  cancelText = 'Cancel',
  isDisabled,
  selectProps = {},
}) => {
  const { execute: handleSelect, status: selectStatus } = useAsync(selectValue);
  const [newSelectedValue, setNewSelectedValue] = useState(); // temporary value saved in state to allow us to display the new value to select while the <Popconfirm> is open; as well as to use that value in its `onConfirm` function
  const [isOpen, setIsOpen] = useState(false); // controls when to show/hide <Popconfirm>

  return (
    <Popconfirm
      open={isOpen}
      title={prompt}
      okText={okText}
      cancelText={cancelText}
      onConfirm={() => {
        setIsOpen(false);
        handleSelect(newSelectedValue)
          .then(onSelected)
          .catch((errorMessage) => {
            message.error(`Update failed. ${errorMessage}`);
          })
          .finally(() => {
            setNewSelectedValue(null); // clear `newSelectedValue`
          });
      }}
      onCancel={() => {
        setIsOpen(false);
        setNewSelectedValue(null); // clear `newSelectedValue`
      }}
      disabled={isDisabled}
      placement="topRight"
    >
      <Select
        options={options}
        onChange={(selectedValue) => {
          setNewSelectedValue(selectedValue);
          setIsOpen(true);
        }}
        value={newSelectedValue ?? value}
        loading={selectStatus === 'pending'}
        optionFilterProp="label"
        {...selectProps}
      />
    </Popconfirm>
  );
};

export const SaveEntityModal = (props) => {
  const [isVisible, setVisibility] = useState(false);
  return (
    <SaveEntityModalComponent isVisible={isVisible} setVisibility={setVisibility} {...props} />
  );
};

export const SaveEntityModalControlledVisibility = ({ isVisible, setVisibility, ...props }) => (
  <SaveEntityModalComponent
    isVisible={isVisible}
    setVisibility={setVisibility}
    triggerRender={() => {}}
    {...props}
  />
);

const SaveEntityModalComponent = ({
  buttonText = 'Add',
  buttonProps = {},
  triggerRender,
  modalTitle,
  okText = 'Save',
  width,
  validateFormOnSubmit, // optional - called after antd field validation, throws error to set form-level error message & halt form submission flow
  transformBeforeSave = (values) => values,
  saveEntity,
  onSuccess,
  formComponent: FormComponent,
  formComponentProps,
  transformBeforeInit = (initialValue) => initialValue,
  formInitialValues,
  isVisible,
  setVisibility,
  isViewOnly, // disables form and hides OK/Save button
}) => {
  const [formInstance] = Form.useForm();

  const {
    execute: handleSave,
    status: saveStatus,
    error: saveError,
    setError: setSaveError,
    reset: resetSaveState,
  } = useAsync(saveEntity);

  const openModal = useCallback(() => {
    // Can't seem to just pass in initialValues to the Form component when using Form.useForm() without bugs occuring, have to do this instead
    formInstance.setFieldsValue(transformBeforeInit(formInitialValues));

    setVisibility(true);
  }, [formInstance, transformBeforeInit, formInitialValues, setVisibility]);

  const closeModal = useCallback(() => {
    setTimeout(() => {
      // Clear the `useAsync` state (required to ensure stale error messages displayed before the modal is closed are no longer present if the modal is reopened)
      resetSaveState();
      // Clear/reset the form fields
      formInstance.resetFields();
    }, 100); // use timeout to avoid content jank as the form modal closes

    setVisibility(false);
  }, [formInstance, resetSaveState, setVisibility]);

  return (
    <>
      {triggerRender ? (
        triggerRender({ openModal })
      ) : (
        <Button type="primary" onClick={openModal} {...buttonProps}>
          {buttonText}
        </Button>
      )}
      <Modal
        title={modalTitle}
        width={width}
        open={isVisible}
        closable={false}
        maskClosable={false}
        okText={okText}
        okButtonProps={isViewOnly ? { disabled: true, style: { display: 'none' } } : {}}
        onOk={async () => {
          let values, formIsValid;
          try {
            values = await formInstance.validateFields();
            if (validateFormOnSubmit) {
              validateFormOnSubmit(values);
            }

            formIsValid = true; // Won't get set if the validation in this try block throws an error
          } catch (error) {
            if (error?.message) {
              // antd field validation won't throw `error.message` - we don't want to show an empty form-level error message in that case
              setSaveError(error);
            }
          }

          if (formIsValid) {
            const transformedValues = transformBeforeSave(values);
            handleSave(transformedValues)
              .then((response) => {
                closeModal();
                return onSuccess(response);
              })
              .catch(() => {
                // We already display the error message in `saveError` so we can swallow `useAsync`'s Promise rejection here
                // TODO: Find a way to swallow (or possibly handle) the useAsync error such that any other errors that get thrown in `onSuccess` can be console'd/handled here (particularly useful for debugging!)
              });
          }
        }}
        confirmLoading={saveStatus === 'pending'}
        onCancel={closeModal}
      >
        <FormComponent
          formInstance={formInstance}
          isViewOnly={isViewOnly}
          initialValues={formInitialValues}
          saveError={saveError}
          {...formComponentProps}
        />
      </Modal>
    </>
  );
};

/**
 * Modal for uses which do not require "Save" & "Cancel", just "Close"
 * (No async action on close.)
 */
export const NoSaveModal = ({
  buttonText,
  buttonProps = {},
  triggerRender,
  footerRender,
  modalTitle,
  width = 660,
  children,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const openModal = () => {
    setVisibility(true);
  };
  const closeModal = () => {
    setVisibility(false);
  };

  return (
    <>
      {triggerRender ? (
        triggerRender({ openModal })
      ) : (
        <Button type="primary" onClick={openModal} {...buttonProps}>
          {buttonText}
        </Button>
      )}
      <Modal
        title={modalTitle}
        width={width}
        open={isVisible}
        closable={false}
        maskClosable={false}
        onCancel={closeModal} // For the escape key
        footer={
          footerRender ? (
            footerRender({ closeModal })
          ) : (
            <Button key="ok" type="primary" onClick={closeModal}>
              Ok
            </Button>
          )
        }
      >
        {children}
      </Modal>
    </>
  );
};
